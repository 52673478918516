import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';

// Handle client-side routing
const currentPath = window.location.pathname;
if (currentPath !== '/' && !document.cookie.includes('ml_etl_access_token')) {
  sessionStorage.setItem('redirect', currentPath);
  window.location.href = '/login';
} else {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}