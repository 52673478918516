import React, { useState, useEffect } from 'react';
import { 
  Loader2, 
  CheckCircle2, 
  XCircle,
  ShoppingCart,
  Plus,
  Power,
  Settings as SettingsIcon,
  Trash2,
  RefreshCw,
  ArrowLeft,
  AlertTriangle
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../services/supabase';
import { mlApi } from '../services/ml-api';

const ML_CLIENT_ID = '2940169115335768';
const REDIRECT_URI = 'https://aremy.life/auth/callback';

interface Connection {
  id: string;
  name: string;
  type: 'mercadolivre';
  status: 'connected' | 'disconnected';
  lastSync?: string;
  details?: {
    userId?: string;
    storeName?: string;
  };
}

export function MercadoLivreSettings() {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null);
  const [showConnectionDetails, setShowConnectionDetails] = useState(false);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [status, setStatus] = useState<{ type: 'success' | 'error' | null; message: string | null }>({
    type: null,
    message: null
  });

  useEffect(() => {
    loadConnections();
  }, []);

  async function loadConnections() {
    try {
      const { data: apiKeys, error } = await supabase
        .from('ml_api_keys')
        .select('*');

      if (error) throw error;

      const formattedConnections: Connection[] = apiKeys.map(key => ({
        id: key.id,
        name: 'MercadoLivre Store',
        type: 'mercadolivre',
        status: key.access_token ? 'connected' : 'disconnected',
        details: {
          userId: key.ml_user_id,
          storeName: 'Minha Loja'
        },
        lastSync: key.updated_at
      }));

      setConnections(formattedConnections);
    } catch (error) {
      console.error('Error loading connections:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleVerifyConnection = async () => {
    try {
      setIsVerifying(true);
      setStatus({ type: null, message: null });

      const result = await mlApi.verifyConnection();
      
      setStatus({
        type: result.success ? 'success' : 'error',
        message: result.message || (result.success ? 'Conexão verificada com sucesso!' : 'Erro ao verificar conexão')
      });
    } catch (error: any) {
      setStatus({
        type: 'error',
        message: error.message || 'Erro ao verificar conexão'
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleConnect = () => {
    setIsConnecting(true);
    setStatus({ type: null, message: null });
    
    const width = 600;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const authUrl = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${ML_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
    
    const popup = window.open(
      authUrl,
      'MLAuth',
      `width=${width},height=${height},left=${left},top=${top},toolbar=0,scrollbars=1,status=1,resizable=1`
    );

    if (!popup) {
      setIsConnecting(false);
      setStatus({
        type: 'error',
        message: 'O popup foi bloqueado. Por favor, permita popups para este site.'
      });
      return;
    }

    const pollTimer = setInterval(() => {
      if (!popup || popup.closed) {
        clearInterval(pollTimer);
        setIsConnecting(false);
      }
    }, 500);

    const handleMessage = (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        try {
          if (event.data.type === 'ML_AUTH_SUCCESS') {
            setStatus({
              type: 'success',
              message: 'Conectado com sucesso ao MercadoLivre!'
            });
            loadConnections();
          } else if (event.data.type === 'ML_AUTH_ERROR') {
            setStatus({
              type: 'error',
              message: event.data.error || 'Falha ao conectar ao MercadoLivre'
            });
          }
        } catch (error) {
          console.error('Error processing message:', error);
          setStatus({
            type: 'error',
            message: 'Erro ao processar resposta da autenticação'
          });
        }
        setIsConnecting(false);
        clearInterval(pollTimer);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
      clearInterval(pollTimer);
    };
  };

  function ConnectionCard({ connection }: { connection: Connection }) {
    return (
      <div
        onClick={() => {
          setSelectedConnection(connection);
          setShowConnectionDetails(true);
        }}
        className={`
          relative p-6 rounded-lg cursor-pointer transition-all
          ${connection.status === 'connected' 
            ? 'bg-white border-2 border-[#2D3277] shadow-lg hover:shadow-xl' 
            : 'bg-gray-50 border border-gray-200 hover:border-gray-300'
          }
        `}
      >
        <div className="absolute top-3 right-3">
          {connection.status === 'connected' ? (
            <div className="flex items-center text-green-500">
              <div className="w-2 h-2 rounded-full bg-green-500 mr-2 animate-pulse" />
              <span className="text-xs font-medium">Online</span>
            </div>
          ) : (
            <div className="flex items-center text-gray-400">
              <div className="w-2 h-2 rounded-full bg-gray-400 mr-2" />
              <span className="text-xs font-medium">Offline</span>
            </div>
          )}
        </div>

        <div className="flex items-center mb-4">
          <img
            src="https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/5.21.22/mercadolibre/logo__large_plus.png"
            alt="MercadoLivre"
            className="h-8 object-contain"
          />
        </div>

        <h3 className={`font-medium ${
          connection.status === 'connected' ? 'text-gray-900' : 'text-gray-500'
        }`}>
          {connection.name}
        </h3>

        {connection.lastSync && (
          <p className="text-xs text-gray-500 mt-2">
            Última sincronização: {new Date(connection.lastSync).toLocaleString()}
          </p>
        )}
      </div>
    );
  }

  function ConnectionDetails() {
    if (!selectedConnection) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">Detalhes da Conexão</h3>
            <button
              onClick={() => setShowConnectionDetails(false)}
              className="text-gray-400 hover:text-gray-600"
            >
              <XCircle className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <p className="text-sm font-medium text-gray-500">Status</p>
              <div className="flex items-center mt-1">
                <div className={`w-2 h-2 rounded-full mr-2 ${
                  selectedConnection.status === 'connected' 
                    ? 'bg-green-500' 
                    : 'bg-gray-400'
                }`} />
                <p className="text-gray-900 capitalize">{selectedConnection.status}</p>
              </div>
            </div>

            {selectedConnection.details?.storeName && (
              <div>
                <p className="text-sm font-medium text-gray-500">Nome da Loja</p>
                <p className="text-gray-900">{selectedConnection.details.storeName}</p>
              </div>
            )}

            {selectedConnection.details?.userId && (
              <div>
                <p className="text-sm font-medium text-gray-500">ID do Usuário</p>
                <p className="text-gray-900">{selectedConnection.details.userId}</p>
              </div>
            )}

            {selectedConnection.lastSync && (
              <div>
                <p className="text-sm font-medium text-gray-500">Última Sincronização</p>
                <p className="text-gray-900">
                  {new Date(selectedConnection.lastSync).toLocaleString()}
                </p>
              </div>
            )}

            <div className="flex gap-3 mt-6">
              <button
                onClick={handleVerifyConnection}
                disabled={isVerifying}
                className="flex-1 flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors disabled:bg-green-300"
              >
                {isVerifying ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Verificando...
                  </>
                ) : (
                  <>
                    <AlertTriangle className="h-4 w-4 mr-2" />
                    Verificar Conexão
                  </>
                )}
              </button>

              <button
                onClick={() => {
                  setShowConnectionDetails(false);
                  handleConnect();
                }}
                className="flex-1 flex items-center justify-center px-4 py-2 bg-[#2D3277] text-white rounded-md hover:bg-[#1E2255] transition-colors"
              >
                {selectedConnection.status === 'connected' ? (
                  <>
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Reconectar
                  </>
                ) : (
                  <>
                    <Power className="h-4 w-4 mr-2" />
                    Conectar
                  </>
                )}
              </button>

              <button
                onClick={async () => {
                  try {
                    await supabase
                      .from('ml_api_keys')
                      .delete()
                      .eq('id', selectedConnection.id);
                    
                    setShowConnectionDetails(false);
                    loadConnections();
                  } catch (error) {
                    console.error('Error removing connection:', error);
                  }
                }}
                className="flex items-center justify-center px-4 py-2 border border-red-500 text-red-500 rounded-md hover:bg-red-50 transition-colors"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-[#2D3277]" />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="mb-8">
        <div className="flex items-center gap-4 mb-4">
          <Link
            to="/settings"
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <ArrowLeft className="h-6 w-6" />
          </Link>
          <img
            src="https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/5.21.22/mercadolibre/logo__large_plus.png"
            alt="MercadoLivre"
            className="h-8 object-contain"
          />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900">Conexões MercadoLivre</h2>
        <p className="mt-1 text-gray-500">Gerencie suas integrações com o MercadoLivre</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {connections.map(connection => (
          <ConnectionCard key={connection.id} connection={connection} />
        ))}
        
        <button
          onClick={handleConnect}
          className="p-6 rounded-lg border-2 border-dashed border-gray-300 flex flex-col items-center justify-center text-gray-500 hover:text-gray-700 hover:border-gray-400 transition-colors"
        >
          <Plus className="h-8 w-8 mb-2" />
          <span className="text-sm font-medium">Adicionar Nova Conexão</span>
        </button>
      </div>

      {showConnectionDetails && <ConnectionDetails />}

      {status.type && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg flex items-center ${
          status.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          {status.type === 'success' ? (
            <CheckCircle2 className="h-5 w-5 mr-2" />
          ) : (
            <XCircle className="h-5 w-5 mr-2" />
          )}
          {status.message}
        </div>
      )}
    </div>
  );
}