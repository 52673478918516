import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AlertCircle, Loader2, KeyRound } from 'lucide-react';
import { supabase } from '../services/supabase';
import { cookieService } from '../services/cookie';
import axios from 'axios';

export function AuthCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showManualInput, setShowManualInput] = useState(true);
  const [manualToken, setManualToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      exchangeCodeForToken(code);
    }
  }, [searchParams]);

  const sendMessageToOpener = (message: { type: string; error?: string }) => {
    if (window.opener) {
      try {
        window.opener.postMessage(message, 'https://aremy.life');
        window.close();
      } catch (e) {
        console.error('Failed to send message to opener:', e);
      }
    }
  };

  const exchangeCodeForToken = async (code: string) => {
    try {
      setIsProcessing(true);

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        throw new Error(`Authentication error: ${userError.message}`);
      }
      
      if (!user) {
        throw new Error('User not authenticated. Please login again.');
      }

      const response = await axios.post('/.netlify/functions/ml-auth', {
        code,
        grant_type: 'authorization_code'
      });

      const { access_token, refresh_token, user_id } = response.data;

      if (!access_token) {
        throw new Error('Invalid response from authentication server');
      }

      // Clear existing cookies before setting new ones
      cookieService.clearMLCookies();
      
      // Set new cookies
      cookieService.setMLCookies({ access_token, refresh_token, user_id });

      await saveTokens(user.id, { access_token, refresh_token, user_id });

      sendMessageToOpener({ type: 'ML_AUTH_SUCCESS' });

      if (!window.opener) {
        navigate('/settings/mercadolivre');
      }
    } catch (err: any) {
      const errorMsg = err.response?.data?.error_description ||
                      err.response?.data?.error ||
                      err.response?.data?.message ||
                      err.message ||
                      'Authentication processing error';
      
      console.error('Token exchange error:', errorMsg);
      setError(errorMsg);
      
      sendMessageToOpener({ type: 'ML_AUTH_ERROR', error: errorMsg });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleManualTokenSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setIsProcessing(true);
      setError(null);

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError || !user) {
        throw new Error('User not authenticated. Please login again.');
      }

      // Clear existing cookies before setting new ones
      cookieService.clearMLCookies();
      
      // Set new cookies
      cookieService.setMLCookies({
        access_token: manualToken,
        refresh_token: refreshToken,
        user_id: 'manual'
      });

      await saveTokens(user.id, {
        access_token: manualToken,
        refresh_token: refreshToken,
        user_id: 'manual'
      });

      navigate('/settings/mercadolivre');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const saveTokens = async (userId: string, data: { access_token: string; refresh_token: string; user_id: string }) => {
    const { error: supabaseError } = await supabase
      .from('ml_api_keys')
      .upsert({
        user_id: userId,
        ml_user_id: data.user_id,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'user_id'
      });

    if (supabaseError) {
      throw new Error(`Error saving tokens: ${supabaseError.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        {isProcessing ? (
          <div className="text-center">
            <Loader2 className="h-12 w-12 text-blue-500 animate-spin mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-gray-900">Processing Authentication...</h2>
            <p className="mt-2 text-gray-600">Please wait while we complete the process...</p>
          </div>
        ) : error || showManualInput ? (
          <>
            {error && !showManualInput && (
              <div className="text-center mb-6">
                <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                <h2 className="text-xl font-semibold text-gray-900">Authentication Error</h2>
                <p className="text-gray-600">{error}</p>
              </div>
            )}

            <form onSubmit={handleManualTokenSubmit} className="space-y-4">
              <div className="text-center mb-6">
                <KeyRound className="h-12 w-12 text-blue-500 mx-auto mb-4" />
                <h2 className="text-xl font-semibold text-gray-900">Enter Token</h2>
                <p className="mt-2 text-gray-600">Enter your MercadoLivre access token</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Access Token
                </label>
                <input
                  type="text"
                  value={manualToken}
                  onChange={(e) => setManualToken(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="APP_USR-0000000000000000-000000-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-000000000"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Refresh Token (optional)
                </label>
                <input
                  type="text"
                  value={refreshToken}
                  onChange={(e) => setRefreshToken(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="TG-00000000000-000000000"
                />
              </div>

              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Save Token
              </button>
            </form>
          </>
        ) : (
          <div className="text-center">
            <div className="w-12 h-12 bg-green-100 text-green-500 rounded-full flex items-center justify-center mx-auto mb-4">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Authentication Complete</h2>
            <p className="text-gray-600">You will be redirected shortly...</p>
          </div>
        )}
      </div>
    </div>
  );
}