import axios from 'axios';
import { Order } from '../types/ml';
import { cookieService } from './cookie';

export class MLApiService {
  private getAccessToken(): string | undefined {
    return cookieService.getMLCookies().access_token;
  }

  async verifyConnection(): Promise<{ success: boolean; message?: string }> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return { success: false, message: 'Token de acesso não encontrado' };
    }

    try {
      const response = await axios.get('https://api.mercadolibre.com/users/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return { 
        success: true, 
        message: `Conectado como ${response.data.nickname} (ID: ${response.data.id})`
      };
    } catch (error: any) {
      let message = 'Erro ao verificar conexão';
      
      if (error.response) {
        if (error.response.status === 401) {
          message = 'Token de acesso inválido ou expirado';
        } else {
          message = error.response.data?.message || error.response.data?.error || 'Erro desconhecido';
        }
      } else if (error.request) {
        message = 'Erro de conexão com o MercadoLivre';
      }

      return { success: false, message };
    }
  }

  async getOrders(offset: number = 0): Promise<Order[]> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      throw new Error('No access token available');
    }

    try {
      const response = await axios.get('https://api.mercadolibre.com/orders/search', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          seller: null,
          offset,
          limit: 50,
          sort: 'date_desc'
        },
      });

      return response.data.results;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw new Error('Failed to fetch orders from MercadoLivre');
    }
  }
}

export const mlApi = new MLApiService();