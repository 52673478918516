import { supabase } from './supabase';

export interface LogEntry {
  id?: string;
  user_id: string;
  action: string;
  details?: any;
  status: 'success' | 'error' | 'info' | 'warning';
  created_at?: string;
}

class LogService {
  private static instance: LogService;
  private subscribers: ((entry: LogEntry) => void)[] = [];

  private constructor() {
    this.setupRealtimeSubscription();
  }

  static getInstance(): LogService {
    if (!LogService.instance) {
      LogService.instance = new LogService();
    }
    return LogService.instance;
  }

  private setupRealtimeSubscription() {
    supabase
      .channel('system_logs')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'system_logs'
        },
        (payload) => {
          this.notifySubscribers(payload.new as LogEntry);
        }
      )
      .subscribe();
  }

  async log(entry: Omit<LogEntry, 'created_at' | 'id'>) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('system_logs')
        .insert({
          ...entry,
          user_id: user.id,
          created_at: new Date().toISOString()
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error logging action:', error);
      // Don't throw the error to prevent app crashes
      return null;
    }
  }

  async getLogs(limit = 50) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        // Return empty array if user is not authenticated
        return [];
      }

      const { data, error } = await supabase
        .from('system_logs')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) {
        console.error('Error fetching logs:', error);
        return [];
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching logs:', error);
      // Return empty array instead of throwing
      return [];
    }
  }

  subscribe(callback: (entry: LogEntry) => void) {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter(cb => cb !== callback);
    };
  }

  private notifySubscribers(entry: LogEntry) {
    this.subscribers.forEach(callback => callback(entry));
  }
}

export const logService = LogService.getInstance();