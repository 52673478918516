import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    storageKey: 'ml-etl-auth',
    storage: window.localStorage,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  }
});

// Enhanced auth state change handling with retry mechanism
supabase.auth.onAuthStateChange(async (event, session) => {
  console.log('Auth state changed:', event);
  
  if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
    // Clear all auth-related data
    localStorage.removeItem('ml-etl-auth');
    localStorage.removeItem('supabase.auth.token');
    // Remove any other app-specific data from localStorage
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('ml-etl-') || key.startsWith('supabase.')) {
        localStorage.removeItem(key);
      }
    });
    
    // Only redirect if we're not already on the login page
    if (!window.location.pathname.includes('/login')) {
      window.location.href = '/login';
    }
  } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
    let retries = 3;
    let lastError = null;
    
    while (retries > 0) {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        if (!user) throw new Error('No user found');
        
        // If successful, break the retry loop
        console.log('Session verified successfully');
        return;
      } catch (error) {
        console.error(`Session verification attempt ${4 - retries} failed:`, error);
        lastError = error;
        retries--;
        
        if (retries > 0) {
          // Wait before retrying (exponential backoff)
          await new Promise(resolve => setTimeout(resolve, (4 - retries) * 1000));
        }
      }
    }
    
    // If all retries fail, sign out and redirect
    console.error('All session verification attempts failed:', lastError);
    await supabase.auth.signOut();
    if (!window.location.pathname.includes('/login')) {
      window.location.href = '/login';
    }
  }
});

// Improved session recovery with retry mechanism
export async function recoverSession() {
  let retries = 3;
  let lastError = null;
  
  while (retries > 0) {
    try {
      console.log(`Attempting to recover session (${retries} retries left)`);
      
      // First, try to get the session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) throw sessionError;
      
      if (session) {
        console.log('Session recovered successfully');
        return true;
      }
      
      // If no session, try to refresh
      const { data: { user }, error: refreshError } = await supabase.auth.getUser();
      if (refreshError) throw refreshError;
      
      if (user) {
        console.log('User found after refresh');
        return true;
      }
      
      throw new Error('No session or user found');
    } catch (error) {
      console.error(`Session recovery attempt ${4 - retries} failed:`, error);
      lastError = error;
      retries--;
      
      if (retries > 0) {
        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, (4 - retries) * 1000));
      }
    }
  }
  
  console.error('All session recovery attempts failed:', lastError);
  return false;
}