import Cookies from 'js-cookie';

const COOKIE_PREFIX = 'ml_etl_';
const COOKIE_DOMAIN = 'aremy.life';
const SECURE = true;
const SAME_SITE = 'strict';

export interface MLCookies {
  access_token?: string;
  refresh_token?: string;
  user_id?: string;
}

export const cookieService = {
  setMLCookies(data: MLCookies) {
    const oneWeek = 7;
    const options = {
      domain: COOKIE_DOMAIN,
      secure: SECURE,
      sameSite: SAME_SITE,
      expires: oneWeek
    };

    // Clear existing cookies first
    this.clearMLCookies();

    // Set new cookies with proper domain and security settings
    if (data.access_token) {
      Cookies.set(`${COOKIE_PREFIX}access_token`, data.access_token, options);
    }
    if (data.refresh_token) {
      Cookies.set(`${COOKIE_PREFIX}refresh_token`, data.refresh_token, options);
    }
    if (data.user_id) {
      Cookies.set(`${COOKIE_PREFIX}user_id`, data.user_id, options);
    }
  },

  getMLCookies(): MLCookies {
    return {
      access_token: Cookies.get(`${COOKIE_PREFIX}access_token`),
      refresh_token: Cookies.get(`${COOKIE_PREFIX}refresh_token`),
      user_id: Cookies.get(`${COOKIE_PREFIX}user_id`)
    };
  },

  clearMLCookies() {
    const options = { 
      domain: COOKIE_DOMAIN,
      secure: SECURE,
      sameSite: SAME_SITE
    };
    
    // Remove all cookies that start with our prefix
    Object.keys(Cookies.get()).forEach(cookie => {
      if (cookie.startsWith(COOKIE_PREFIX)) {
        Cookies.remove(cookie, options);
      }
    });

    // Explicitly remove known cookies
    Cookies.remove(`${COOKIE_PREFIX}access_token`, options);
    Cookies.remove(`${COOKIE_PREFIX}refresh_token`, options);
    Cookies.remove(`${COOKIE_PREFIX}user_id`, options);
  }
};