import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ShoppingCart, Loader2, CheckCircle2, XCircle } from 'lucide-react';
import { supabase } from '../services/supabase';

const ML_CLIENT_ID = '2940169115335768';
// Using aremy.life domain
const REDIRECT_URI = 'https://aremy.life/auth/callback';

export function Settings() {
  const [isConnecting, setIsConnecting] = useState(false);
  const [status, setStatus] = useState<{ type: 'success' | 'error' | null; message: string | null }>({
    type: null,
    message: null
  });

  const handleConnect = () => {
    setIsConnecting(true);
    setStatus({ type: null, message: null });
    
    const width = 600;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const authUrl = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${ML_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
    
    const popup = window.open(
      authUrl,
      'MLAuth',
      `width=${width},height=${height},left=${left},top=${top},toolbar=0,scrollbars=1,status=1,resizable=1`
    );

    if (!popup) {
      setIsConnecting(false);
      setStatus({
        type: 'error',
        message: 'O popup foi bloqueado. Por favor, permita popups para este site.'
      });
      return;
    }

    const pollTimer = setInterval(() => {
      if (!popup || popup.closed) {
        clearInterval(pollTimer);
        setIsConnecting(false);
      }
    }, 500);

    const handleMessage = (event: MessageEvent) => {
      if (event.origin === 'https://aremy.life') {
        try {
          if (event.data.type === 'ML_AUTH_SUCCESS') {
            setStatus({
              type: 'success',
              message: 'Conectado com sucesso ao MercadoLivre!'
            });
            window.location.href = '/settings/mercadolivre';
          } else if (event.data.type === 'ML_AUTH_ERROR') {
            setStatus({
              type: 'error',
              message: event.data.error || 'Falha ao conectar ao MercadoLivre'
            });
          }
        } catch (error) {
          console.error('Error processing message:', error);
          setStatus({
            type: 'error',
            message: 'Erro ao processar resposta da autenticação'
          });
        }
        setIsConnecting(false);
        clearInterval(pollTimer);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
      clearInterval(pollTimer);
    };
  };

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-900">Configurações</h2>
        <p className="mt-1 text-gray-500">Gerencie suas integrações e preferências</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <button
          onClick={handleConnect}
          disabled={isConnecting}
          className="p-6 rounded-lg bg-white border border-gray-200 hover:border-[#2D3277] hover:shadow-lg transition-all group text-left"
        >
          <div className="flex items-center mb-4">
            {isConnecting ? (
              <Loader2 className="h-8 w-8 text-[#2D3277] animate-spin" />
            ) : (
              <img
                src="https://http2.mlstatic.com/frontend-assets/ml-web-navigation/ui-navigation/5.21.22/mercadolibre/logo__large_plus.png"
                alt="MercadoLivre"
                className="h-8 object-contain"
              />
            )}
          </div>
          <h3 className="font-medium text-gray-900 group-hover:text-[#2D3277]">
            MercadoLivre
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            {isConnecting ? 'Conectando...' : 'Conecte-se ao MercadoLivre'}
          </p>
        </button>

        {/* Placeholder for future integrations */}
        <div className="p-6 rounded-lg bg-gray-50 border border-gray-200">
          <div className="flex items-center mb-4">
            <ShoppingCart className="h-8 w-8 text-gray-400" />
          </div>
          <h3 className="font-medium text-gray-400">Em breve</h3>
          <p className="mt-2 text-sm text-gray-400">
            Novas integrações serão adicionadas em breve
          </p>
        </div>
      </div>

      {status.type && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg flex items-center ${
          status.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          {status.type === 'success' ? (
            <CheckCircle2 className="h-5 w-5 mr-2" />
          ) : (
            <XCircle className="h-5 w-5 mr-2" />
          )}
          {status.message}
        </div>
      )}
    </div>
  );
}