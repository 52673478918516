import React, { useEffect, useState } from 'react';
import { LogEntry, logService } from '../services/log';
import { AlertCircle, CheckCircle, Info, AlertTriangle, X, Loader2 } from 'lucide-react';
import { format } from 'date-fns';

export function LogViewer() {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    loadLogs();
    const unsubscribe = logService.subscribe((newLog) => {
      setLogs(prev => [newLog, ...prev]);
    });
    return () => unsubscribe();
  }, []);

  const loadLogs = async () => {
    try {
      const data = await logService.getLogs();
      setLogs(data);
    } catch (error) {
      console.error('Error loading logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusIcon = (status: LogEntry['status']) => {
    switch (status) {
      case 'success':
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case 'error':
        return <AlertCircle className="h-4 w-4 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="h-4 w-4 text-yellow-500" />;
      default:
        return <Info className="h-4 w-4 text-blue-500" />;
    }
  };

  if (isLoading) {
    return (
      <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4">
        <Loader2 className="h-5 w-5 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white rounded-full p-2 shadow-lg hover:shadow-xl transition-shadow"
      >
        <Info className={`h-6 w-6 ${isOpen ? 'text-blue-500' : 'text-gray-500'}`} />
      </button>

      {isOpen && (
        <div className="absolute bottom-12 right-0 w-96 max-h-96 bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="flex items-center justify-between p-3 border-b">
            <h3 className="font-medium">Log do Sistema</h3>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-4 w-4" />
            </button>
          </div>

          <div className="overflow-y-auto max-h-80">
            {logs.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                Nenhum log encontrado
              </div>
            ) : (
              <div className="divide-y">
                {logs.map((log) => (
                  <div key={log.id} className="p-3 hover:bg-gray-50">
                    <div className="flex items-center gap-2">
                      {getStatusIcon(log.status)}
                      <span className="font-medium">{log.action}</span>
                    </div>
                    {log.details && (
                      <pre className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
                        {typeof log.details === 'string' 
                          ? log.details 
                          : JSON.stringify(log.details, null, 2)}
                      </pre>
                    )}
                    <div className="mt-1 text-xs text-gray-400">
                      {format(new Date(log.created_at!), 'dd/MM/yyyy HH:mm:ss')}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}