import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Database, Settings as SettingsIcon, User, LogOut } from 'lucide-react';
import { supabase } from '../services/supabase';
import { cookieService } from '../services/cookie';

export function Layout() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      cookieService.clearMLCookies();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex items-center px-2 py-2 text-gray-900">
                <Database className="h-6 w-6 mr-2" />
                <span className="font-semibold">ML ETL Tool</span>
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              <Link
                to="/profile"
                className="p-2 text-gray-500 hover:text-gray-900 transition-colors"
                title="Perfil"
              >
                <User className="h-6 w-6" />
              </Link>
              <Link
                to="/settings"
                className="p-2 text-gray-500 hover:text-gray-900 transition-colors"
                title="Configurações"
              >
                <SettingsIcon className="h-6 w-6" />
              </Link>
              <button
                onClick={handleSignOut}
                className="p-2 text-gray-500 hover:text-gray-900 transition-colors"
                title="Sair"
              >
                <LogOut className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </main>
    </div>
  );
}